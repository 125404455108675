import React from "react";

import Layout from "../components/layout";

const IndexPage = () => (
  <Layout>
    <h1>Hiya</h1>
    <p>You've reached Sam and Tom's space.</p>
    <p>There isn't much to see here right now.</p>
    <p>
      If you're looking for our wedding website&nbsp;
      <a href={process.env.WEDDING_URL}>it's located here.</a>
    </p>
  </Layout>
);

export default IndexPage;
